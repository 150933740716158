import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserService } from '../auth.details';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { requestDashboard } from './_interface/user-request-dashboard';
import { RequestFormComponent } from '../request-form/request-form.component';
import { SpinnerServiceService } from '../services/spinner-service.service';
import { CommonHelperService } from '../services/common-helper.service';
import { WindowRefService } from '../services/window.service';

interface ColumnDef<T> {
  columnDef: string;
  header: string;
  cell: (element: T) => string;
}

@Component({
  selector: 'app-user-request-dashboard',
  templateUrl: './user-request-dashboard.component.html',
  styleUrl: './user-request-dashboard.component.css'
})

export class UserRequestDashboardComponent implements OnInit, AfterViewInit {
  columns!: ColumnDef<any>[];
  isAdmin!: boolean;
  searchText: string = '';
  filters: { [key: string]: any[] } = {};
  firstAppliedFilterColumn:any=[];
  isAllFiltersNotRemoved:boolean=false;
  dataSource: any;
  displayedColumns: any;
  isLoading = false;
  isGridDataAvailable: boolean = true;
  currentPageIndex = 0;
  filterShownData:any=[];
  yearsList:any=[];
  initialLoadData:any=[];
  selectedYear:any;
  constructor(private http: HttpClient, private dialog: MatDialog, private appInsightsService: AppInsightsService,
    private datePipe: DatePipe, private userService: UserService,private spinnerService:SpinnerServiceService,
    private commonHelperService:CommonHelperService,private windowService: WindowRefService) { }

  userDisplayedColumns = ['RequestStatus', 'BillingStatus', 'WBSClientName', 'Application', 'RequestDate', 'ServiceOrganization', 'Created', 'RequestorsName','RequestorEmail'];
  userColumns = [
    { columnDef: 'RequestStatus', header: 'Request Status', cell: (element: any) => `${element.RequestStatus}` },
    { columnDef: 'BillingStatus', header: 'Billing Status', cell: (element: any) => `${element.BillingStatus}` },
    { columnDef: 'WBSClientName', header: 'WBS#: ClientName', cell: (element: any) => `${element.WBSClientName}` },
    { columnDef: 'Application', header: 'Application', cell: (element: any) => `${element.Application}` },
    { columnDef: 'RequestDate', header: 'Request Date', cell: (element: any) => `${element.RequestDate}` },
    { columnDef: 'ServiceOrganization', header: 'Service Organization', cell: (element: any) => `${element.ServiceOrganization}` },
    { columnDef: 'Created', header: 'Created', cell: (element: any) => `${element.Created}` },
    { columnDef: 'RequestorsName', header: "Requestor's Name", cell: (element: any) => `${element.RequestorsName}` },
    { columnDef: 'RequestorEmail', header: 'Requestor Email', cell: (element: any) => `${element.RequestorEmail}` },
  ];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;

  ngOnInit() {
    this.appInsightsService.logPageView('UserRequestDashboard');
    this.spinnerService.showSpinner();// show spinner 
    this.selectedYear=this.commonHelperService.defaultYearLoader();
    this.yearsList=this.commonHelperService.generateArrayOfYears();
    this.displayedColumns = this.userDisplayedColumns;
    this.columns = this.userColumns;
    this.dataSource = new MatTableDataSource<requestDashboard>();
    this.getUserRequestData(1, 20, "RequestDate", true, this.userService.userEmail, this.userService.userName);
    if (this.windowService.nativeWindow._satellite) {
      //alert("got the satellite obj");           


      let analyticsDataLayer =
      {
        'pageInfo':
        {
          'pageName': "User Request Dashboard", // Page Name
          'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
          'applicationName': "SAR" // Name of application
        }
      }
     
      this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
      this.windowService.nativeWindow._satellite.track("pageView");
     
    }
  }
  onYearChange(){
    //this.getUserRequestData(1, 20, "RequestDate", true, this.userService.userEmail);
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.customFilter();
    if (this.paginator) {
      this.paginator.page.subscribe((pageEvent: PageEvent) => {
        this.currentPageIndex = pageEvent.pageIndex;
       // this.getUserRequestData(pageEvent.pageIndex + 1, pageEvent.pageSize, "RequestDate", true, this.userService.userEmail);
       this.dataSource.data= this.initialLoadData;//this.commonHelperService.loadPageWiseData(pageEvent.pageIndex + 1, pageEvent.pageSize,this.initialLoadData);
       setTimeout(() => {
        this.paginator.pageIndex = pageEvent.pageIndex + 1 - 1;
        this.paginator.pageSize = pageEvent.pageSize;
        this.paginator.length = this.dataSource.data.length;
      }, 0);
      });
    }
  }

  //DATASOURCE
  public getUserRequestData = (pageNumber: number, pageSize: number, sortBy: string, sortDescending: boolean, createdBy: string,createdByName:string) => {
    this.isLoading = true;
    
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('sortBy', sortBy);
    params = params.append('sortDescending', sortDescending.toString());
    params = params.append('useremail', createdBy.toString());
    params = params.append('username', createdByName.toString());
    //params = params.append('searchText', this.selectedYear.toString());
    const options = {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    };

    this.http.get<{ Data: requestDashboard[], Total: number }>(environment.baseUrl + '/RequestList/GetUserRequestList', options).subscribe(
      response => {
        this.dataSource.data = response.Data;
        if(response.Data.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
        this.initialLoadData=response.Data;
        let defaultData={
          key:"",
          value:response.Data
        }
        this.filterShownData.push(defaultData);
        this.dataSource.data = this.dataSource.data.map((obj: requestDashboard) => {
          if (obj.BillingStatus == null || obj.BillingStatus == "null" || obj.BillingStatus == "NULL") {
            obj.BillingStatus = "";
          }
          if (obj.RequestStatus == null || obj.RequestStatus == "null" || obj.RequestStatus == "NULL") {
            obj.RequestStatus = "";
          }
          if (obj.WBSClientName == null || obj.WBSClientName == "null" || obj.WBSClientName == "NULL") {
            obj.WBSClientName = "";
          }
          if (obj.Application == null || obj.Application == "null" || obj.Application == "NULL") {
            obj.Application = "";
          }
          if (obj.ServiceOrganization == null || obj.ServiceOrganization == "null" || obj.ServiceOrganization == "NULL") {
            obj.ServiceOrganization = "";
          }
          if (obj.RequestDate == null || obj.RequestDate == "null" || obj.RequestDate == "NULL") {
            obj.RequestDate = "";
          } else {
            obj.RequestDate = obj.RequestDate.substring(0, 10);
          }
          return obj;
        })
        this.isLoading = false;
        this.spinnerService.hideSpinner();// hide spinner
        setTimeout(() => {
          this.paginator.pageIndex = pageNumber - 1;
          this.paginator.pageSize = pageSize;
          this.paginator.length = response.Total;
        }, 0);
      },
      error => {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while fetching data!', error);
      }
    );
  }

  //POPUP
  openDialog() {
    this.dialog.open(RequestFormComponent, {
      panelClass: 'custom-dialog',
      width: '800px',
      height: '550px',
      disableClose: true
    });
  }

  //FILTER
  getUniqueColumnValues(columnName: string): string[] {
    // Get the filtered data
    let filteredData:any;
    filteredData=this.dataSource.filteredData;
 if(this.firstAppliedFilterColumn.length>0){
   this.filterShownData.forEach((element: any) => {
     if (element.key == columnName) {
       filteredData = element.value;
     }
   })
}

    const columnValues = filteredData.map((item: any) => {
      if (item[columnName] instanceof Date) {
        // Format the date
        const date = new Date(item[columnName]);
        const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy');
        // Check if formattedDate is not null before using it
        if (formattedDate) {
          return this.commonHelperService.ValidateFilterData(formattedDate);
        }
      }
      return this.commonHelperService.ValidateFilterData(item[columnName]);
    });
    return [...new Set(columnValues as string[])];
  }


  selectedFilters: any = {};

  tempFilters: any = {};

  filterOptions: { [key: string]: string[] } = {};
  ValidateIsFilterExists(){
    this.displayedColumns.forEach((column:any)=>{
      if(this.tempFilters[column]?.length>0){this.isAllFiltersNotRemoved=true;}
      if(this.tempFilters[column]?.length==0){delete this.tempFilters[column];}
    })
  }
  getfiltercolour(column: string) {
    const hasIdKey = Object.prototype.hasOwnProperty.call(this.tempFilters, column);
    return hasIdKey;
  }
  applyFilter(event: any, option: any, column: string) {
    // Convert option to a string
    this.isAllFiltersNotRemoved=false;
    let columnWiseFilterOptions:any={};
    let filteredValueAlreadyExists=false;
    const optionStr =option==null||option==undefined||option=="null"||option=="NULL"?"": String(option);
    if(this.firstAppliedFilterColumn.length==0){
      this.firstAppliedFilterColumn.push(column);
      columnWiseFilterOptions = {
        key: column,
        value: this.dataSource.filteredData
      }
      this.filterShownData.push(columnWiseFilterOptions);
    }
      else{
        this.firstAppliedFilterColumn.forEach((element:any) => {
          if(element==column){
            filteredValueAlreadyExists=true;
          }
        });
        if(!filteredValueAlreadyExists){this.firstAppliedFilterColumn.push(column);
          columnWiseFilterOptions={ 
            key:column,
            value:this.dataSource.filteredData
           }
              this.filterShownData.push(columnWiseFilterOptions);
        }
      }
    // If the checkbox is checked, add the option to the temporary filters
    if (event.checked) {
      this.commonHelperService.LoadFilterAppliedFields(event?.source?.id,column);
      this.isAllFiltersNotRemoved=true;
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.loadFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      if (this.tempFilters[column]) {
        this.tempFilters[column].push(optionStr ? optionStr.toLowerCase() : '');
      } else {
        this.tempFilters[column] = [optionStr ? optionStr.toLowerCase() : ''];
      }
    }
    } else {
      this.commonHelperService.RemoveFilterAppliedFields(event?.source?.id,column);
      // If the checkbox is not checked, remove the option from the temporary filters
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.removeFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      const index = this.tempFilters[column].indexOf(optionStr ? optionStr.toLowerCase() : '');
      if (index >= 0) {
        this.tempFilters[column].splice(index, 1);
      }
    }
      this.ValidateIsFilterExists();
    }
    if(this.isAllFiltersNotRemoved){this.applyFilters();}else{this.clearFilter("");}
  }


  customFilterBck(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return Object.keys(searchTerms).every(name => {
        if (searchTerms[name].length === 0) {
          return true;  // If no filters are set on this column, then return true
        }
        let dataStr = '';
        if (data[name]) {
          // Convert data[name] to a string
          dataStr = String(data[name]).trim().toLowerCase();
        }
        return searchTerms[name].includes(dataStr);
      });
    };
    return filterFunction;
  }
  customFilter(): (data: any, filter: string) => boolean {
    return (data: any, filter: string): boolean => {
      const { searchText, filters } = JSON.parse(filter);
      const globalMatch = !searchText || Object.values(data).some(value =>
        String(value).toLowerCase().includes(searchText)
      );

      const columnMatch = Object.keys(filters).every(column => {
        return filters[column].includes(String(data[column]).toLowerCase());
      });

      return globalMatch && columnMatch;
    };
  }
  applyFilters() {
    this.ValidateIsFilterExists();
    // Copy tempFilters to selectedFilters
    this.selectedFilters = JSON.parse(JSON.stringify(this.tempFilters));
    // Trigger the filter
   // this.dataSource.filter = JSON.stringify(this.selectedFilters);
   if(!this.isAllFiltersNotRemoved){
    this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: {} });
   }else{
    this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: this.selectedFilters });
   }
    if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
    // Update the filter options for all columns
    this.columns.forEach(column => {
      const uniqueValues = this.getUniqueColumnValues(column.columnDef);
      // Update the filter options for the column
      this.updateFilterOptions(column.columnDef, uniqueValues);
    });
  }

  updateFilterOptions(column: string, uniqueValues: string[]) {
    this.filterOptions[column] = uniqueValues;
  }


  clearFilter(column: string) {
    this.isAllFiltersNotRemoved=false;
    // Remove the filters for the column
    delete this.selectedFilters[column];
    delete this.tempFilters[column];
    this.ValidateIsFilterExists();
     // Reset the checkboxes
     this.clearCheckboxes(column);
    // this.checkboxes.forEach((checkbox:any) => {
    //   this.commonHelperService.filterAppliedFields.forEach((element:any) => {
    //     if(element.value==column&&checkbox.id==element.key||column==""){
    //       checkbox.checked = false;
    //     }
    //   });
    // });

    // Reset the filter predicate to the original one
    //this.dataSource.filterPredicate = this.customFilter();
    if(this.isAllFiltersNotRemoved||this.searchText!=""){
      this.applyFilters();
    }
    else{
      this.firstAppliedFilterColumn=[];
      this.filterShownData=[];
    // Trigger the filter
    this.dataSource.filter = "";

      // Fetch the data without any filters
      this.getUserRequestData(this.currentPageIndex + 1, this.paginator.pageSize, "RequestDate", true, this.userService.userEmail, this.userService.userName);
    }
  }
  clearCheckboxes(column:any) {
    this.checkboxes.forEach((checkbox: MatCheckbox) => {
      this.commonHelperService.filterAppliedFields.forEach((element:any) => {
        if(element.value==column&&checkbox.id==element.key||column==""){
          checkbox.checked = false;
        }
      });
    });
  }
  applyGlobalFilter(filterValue: string) {
    // Show the spinner
    this.spinnerService.showSpinner();
    // delay to ensure spinner is visible
    setTimeout(() => {
    this.searchText = filterValue.trim().toLowerCase();
      this.applyFilters();
      // Hide the spinner after data is loaded
      this.spinnerService.hideSpinner();
    }, 500); //delay
  }

  resetFilters() {
    this.firstAppliedFilterColumn=[];
    this.filterShownData=[];
    this.searchText = '';
    this.filters = {};
    this.selectedFilters = {};
    this.tempFilters = {};
    this.applyFilters();
    this.clearCheckboxes("");
  }
  applyGlobalFilterBck(filterValue: any) {
    // Reset the filter predicate to the default
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      // Transform the data into a lowercase string of key value pairs.
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        // Check if data[key] is not null before concatenating
        return currentTerm + (data[key] ? data[key] : '') + '◬';
      }, '').toLowerCase();

      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();

      return dataStr.indexOf(transformedFilter) != -1;
    };

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetFiltersBck() {
    this.firstAppliedFilterColumn=[];
    this.filterShownData=[];
    // Clear the filter value
    this.dataSource.filter = '';
    this.searchText = '';

    // Clear the selected and temporary filters
    this.selectedFilters = {};
    this.tempFilters = {};

    // Reset the checkboxes
    this.checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });

    // Reset the filter predicate to the original one
    this.dataSource.filterPredicate = this.customFilter();

    this.sort.active = '';
    this.sort.direction = '';

    // Fetch the data without any filters
    this.getUserRequestData(this.currentPageIndex + 1, this.paginator.pageSize, "RequestDate", true, this.userService.userEmail, this.userService.userName);
  }

}
