import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '../auth.details';
import { masterDashboard } from '../master-dashboard/_interface/master-dashboard.model';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';

@Component({
  selector: 'app-master-form',
  templateUrl: './master-form.component.html',
  styleUrl: './master-form.component.css'
})
export class MasterFormComponent {
  isEditMode: boolean;
  constructor(private datePipe: DatePipe, private router: Router,
    private http: HttpClient, private userService: UserService, private appInsightsService: AppInsightsService,
    public dialogRef: MatDialogRef<MasterFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isEditMode = data.isEditMode;
  }

  ngOnInit() {
    this.appInsightsService.logPageView('MasterForm');
  }

  onCloseClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/masterDashboard']);
  }

  saveChanges(): void {
    this.updateMasterList(this.data, Number(this.data.ID), this.userService.userEmail);
  }

  public updateMasterList = (requestParameters: masterDashboard, ID: number, modifiedByEmail: string) => {
    requestParameters.ModifiedByName = this.userService.userName.toString();
    let params = new HttpParams();
    params = params.append('modifiedByEmail', modifiedByEmail.toString());
    params = params.append('ID', ID);
    const options = {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    };

    this.http.post(environment.baseUrl + '/MasterList/UpdateMasterList', requestParameters, options).subscribe({
      next: response => {
        this.appInsightsService.logEvent('Master Form Save:', { Response: response });
        location.reload();
      },
      error: error => {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while fetching data!', error);
        this.dialogRef.close();
      }
    });
  }

  navigateToRequestReview() {
    this.router.navigate(['/existingRequestForm', this.data.ID, this.data.IDNumber, this.data.SARReportType]);
    this.dialogRef.close();
  }

  get formattedStartDate(): string {
    return this.data.ReportPeriodStartDate
      ? this.datePipe.transform(this.data.ReportPeriodStartDate, 'yyyy-MM-dd') || ''
      : '';
  }

  onStartDateChange(newDate: string) {
    this.data.ReportPeriodStartDate = new Date(newDate);
  }

  get formattedEndDate(): string {
    return this.data.ReportPeriodEndDate
      ? this.datePipe.transform(this.data.ReportPeriodEndDate, 'yyyy-MM-dd') || ''
      : '';
  }

  onEndDateChange(newDate: string) {
    this.data.ReportPeriodEndDate = new Date(newDate);
  }
}
