import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { WBSRecords } from './wbs-records.interface';
import { environment } from '../../environments/environment';
import { AppInsightsCore } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from '../app-insights.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-wbs-modal',
  templateUrl: './wbs-modal.component.html',
  styleUrls: ['./wbs-modal.component.css']
})
export class WbsModalComponent implements OnInit {
  private searchSubscription!: Subscription;
  isLoading = false;
  wbsRecords: WBSRecords[] = [];
  selectedRecord: WBSRecords = {
    ID: 0,
    WBSElement: '',
    ClientName: '',
    ClientFiscalYearEnd: '',
    ProjectTypeDesc: '',
    CompanyCode: '',
    WBSDesc: '',
    WBSStatusDesc: ''
  };

  searchTerm: string = '';

  constructor(private http: HttpClient, public dialogRef: MatDialogRef<WbsModalComponent>, public appInsightsService: AppInsightsService,
  ) { }

  ngOnInit(): void {
    this.getWbsRecords();
  }

  getWbsRecords() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    };

    this.http.get<WBSRecords[]>(environment.baseUrl + '/RequestList/GetWBSRecords',options).subscribe((data: WBSRecords[]) => {
      this.wbsRecords = data;
    }, error => {
      this.appInsightsService.logException(error);
      this.appInsightsService.logEvent('There was an error while fetching data!', error);
    });
  }

  searchWbsRecords(wbselement: string) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    if (wbselement.trim() === "") {
      this.getWbsRecords();
      return;
    }

    this.isLoading = true;
    this.searchSubscription = this.http.get<WBSRecords[]>(environment.baseUrl + `/RequestList/SearchWBSRecords?wbselement=${wbselement}`).subscribe(
      data => {
        data = data == null ? [] : data; // Added to show the proper message in UI
        this.wbsRecords = data;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while fetching data!', error);
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectRecordAndClose(): void {
    this.dialogRef.close(this.selectedRecord);
  }

  selectRecord(record: WBSRecords): void {
    this.selectedRecord = record;
  }

}
