import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { masterDashboard, masterExport } from './_interface/master-dashboard.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MasterFormComponent } from '../master-form/master-form.component';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserService } from '../auth.details';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { SpinnerServiceService } from '../services/spinner-service.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { CommonHelperService } from '../services/common-helper.service';
import { AlertPopupComponent } from '../notify-popup/notify-popup.component';
import { Observable } from 'rxjs';
import { WindowRefService } from '../services/window.service';


@Component({
  selector: 'app-master-dashboard',
  templateUrl: './master-dashboard.component.html',
  styleUrl: './master-dashboard.component.css'
})

export class MasterDashboardComponent implements OnInit, AfterViewInit {
  isLoading = false;
  isAdmin!: boolean;
  searchText: string = '';
  currentPageIndex = 0;
  firstAppliedFilterColumn:any=[];
  filterShownData:any=[];
  isAllFiltersNotRemoved:boolean=false;
  yearsList:any=[];
  filters: { [key: string]: any[] } = {};
  selectedYear:any="";
  initialLoadData:any=[];
  isGridDataAvailable:any=true;

  public dataSource = new MatTableDataSource<masterDashboard>();
  public displayedColumns = ['ServiceOrganization', 'Application', 'ServiceSector', 'ServiceAuditor', 'ReportPeriodStartDate',
    'ReportPeriodEndDate', 'IDNumber', 'RequestReview', 'ExclusivetoClient', 'SARReportType', 'ComplianceAssetId',
    'Created', 'CreatedBy', 'ID', 'LocationsCovered', 'Modified', 'ModifiedBy', 'SARAuthoritativeGuidance', 'TypeOfOpinion'
  ];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;

  ngOnInit() {
    this.selectedYear=this.commonHelperService.defaultYearLoader();
    this.yearsList=this.commonHelperService.generateArrayOfYears();
    this.appInsightsService.logPageView('MasterDashboard');
    this.spinnerService.showSpinner(); //show spinner
    this.isAdmin = this.userService.isAdmin;
    this.getMasterData(1, 50, "ID", false);
    if (this.windowService.nativeWindow._satellite) {
      //alert("got the satellite obj");           


      let analyticsDataLayer =
      {
        'pageInfo':
        {
          'pageName': "Master Dashboard", // Page Name
          'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
          'applicationName': "SAR" // Name of application
        }
      }
     
      this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
      this.windowService.nativeWindow._satellite.track("pageView");
     
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.customFilter();
    if (this.paginator) {
      this.paginator.page.subscribe((pageEvent: PageEvent) => {
        this.currentPageIndex = pageEvent.pageIndex;
        //this.getMasterData(pageEvent.pageIndex + 1, pageEvent.pageSize, "ID", false);
        this.dataSource.data= this.initialLoadData;//this.commonHelperService.loadPageWiseData(pageEvent.pageIndex + 1, pageEvent.pageSize,this.initialLoadData);
        setTimeout(() => {
         this.paginator.pageIndex = pageEvent.pageIndex + 1 - 1;
         this.paginator.pageSize = pageEvent.pageSize;
         this.paginator.length = this.dataSource.data.length;
       }, 0);
      });
    }
  }

  constructor(private http: HttpClient, private dialog: MatDialog, private appInsightsService: AppInsightsService,
    private datePipe: DatePipe, private userService: UserService,private spinnerService:SpinnerServiceService,private commonHelperService:CommonHelperService, private windowService: WindowRefService) { }

  //DATASOURCE
  public getMasterData = (pageNumber: number, pageSize: number, sortBy: string, sortDescending: boolean,showSuccessDialog:boolean=false) => {
    this.isLoading = true;
    
    let params = new HttpParams();
    params = params.append('searchText', this.selectedYear.toString());
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('sortBy', sortBy);
    params = params.append('sortDescending', sortDescending.toString());

      const options = {
        params: params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("idToken")          
        }) };

    this.http.get<{ Data: masterDashboard[], Total: number }>(environment.baseUrl + '/MasterList/GetMasterList',  options ).subscribe(
      response => {
        this.dataSource.data = response.Data;
        if(response.Data.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
        this.initialLoadData=response.Data;
        let defaultData={
          key:"",
          value:response.Data
        }
        this.filterShownData.push(defaultData);
        this.isLoading = false;
        this.spinnerService.hideSpinner(); //hide spinner
        setTimeout(() => {
          //this.dataSource.sort = this.sort;
          this.paginator.pageIndex = pageNumber - 1;
          this.paginator.pageSize = pageSize;
          this.paginator.length = response.Total;
        }, 0);
        if(showSuccessDialog)
          this.openAlertDialog('Success', 'Item deleted successfully', false);
      },
      error => {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while fetching data!', error);
      }
    );
  }
  onYearChange(){
    this.spinnerService.showSpinner();
    this.resetFiltersBck();
    //this.getMasterData(1, 50, "ID", false);
  }

  getfiltercolour(column: string) {
    const hasIdKey = Object.prototype.hasOwnProperty.call(this.tempFilters, column);
    return hasIdKey;
  }
 public exportToExcel()
 {
    this.isLoading = true;
    this.spinnerService.showSpinner();// show spinner
    let params = new HttpParams();

   const options = {
     params: params,
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': "Bearer " + localStorage.getItem("idToken")
     })
   };
    this.http.get<Array<masterExport>>(environment.baseUrl + '/MasterList/GetExportList', options).subscribe(
      response => {

        if(response!=null && response!=undefined && response.length > 0)
        {
          this.spinnerService.hideSpinner();// hide spinner

          var jsonData:Array<any> = [];
          jsonData = response.map(doc => Object.values(doc));
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
          
          worksheet['A1'].v = "Service Organization";
          worksheet['B1'].v = "Application";
          worksheet['C1'].v = "Service Sector";
          worksheet['D1'].v = "Service Auditor";
          worksheet['E1'].v = "Report Period Start Date";
          worksheet['F1'].v = "Report Period End Date";
          worksheet['G1'].v = "ID Number";
          worksheet['H1'].v = "Exclusive to Client";
          worksheet['I1'].v = "SAR Report Type";
          worksheet['J1'].v = "Compliance Asset Id";
          worksheet['K1'].v = "Created";
          worksheet['L1'].v = "Created By";
          worksheet['M1'].v = "ID";
          worksheet['N1'].v = "Locations Covered in Report";
          worksheet['O1'].v = "Modified";
          worksheet['P1'].v = "Modified By";
          worksheet['Q1'].v = "SAR Authoritative Guidance";
          worksheet['R1'].v = "Type Of Opinion";
          
          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
          this.isLoading = false;
          FileSaver.saveAs(data, 'SAR_Master_List_' + new Date().getTime() + ".xlsx");
      }
      else
      {
          //put alert message here.
          this.isLoading = false;
      }
        
      },
      error => {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while exporting excel data!', error);
      }
    );
  }
  openAlertDialog(title: string, message: string, isGlobalMFWBSCodesPage: boolean,msglength:string="2"): Observable<boolean> {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: { title, message, isGlobalMFWBSCodesPage },
      width: '40%' ,
      height: msglength == "2" ? '30%' : '48%',
      disableClose: true
    });

    return new Observable<boolean>(observer => {
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result); // Emit the result from the dialog
        observer.complete();
      });
    });
  }

  //DELETEITEM
  async deleteItem(element: any) {  
    this.openAlertDialog('Warning', 'Are you sure you want to Delete the item?', true)
    .subscribe((confirmed: boolean) => {
      if (confirmed) {
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem("idToken")
          })
        };

        this.http.delete(environment.baseUrl + `/MasterList/DeleteMasterList/${element.ID}`, options).subscribe(
          () => {
            // Handle successful deletion here, e.g., refresh the data
            this.getMasterData(this.paginator.pageIndex + 1, this.paginator.pageSize, "ID", false,true);            
          },
          error => {
            this.appInsightsService.logException(error);
            this.appInsightsService.logEvent('There was an error while deleting the item!', error);
          }
        );
      }
    });
  }


  //EDIT ITEM
  isEditMode = false;

  editItem(element: any) {
    this.isEditMode = true;
    const dialogRef = this.dialog.open(MasterFormComponent, {
      data: {
        ...element,
        isEditMode: this.isEditMode
      }, panelClass: 'custom-dialog', height: '510px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  //VIEW ITEM
  viewItem(element: any) {
    this.isEditMode = false;
    const dialogRef = this.dialog.open(MasterFormComponent, {
      data: {
        ...element,
        isEditMode: this.isEditMode
      }, panelClass: 'custom-dialog', height: '510px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  //FILTER
  getUniqueColumnValues(columnName: string): string[] {
    // Get the filtered data
    let filteredData:any;
    filteredData=this.dataSource.filteredData;
 if(this.firstAppliedFilterColumn.length>0){
   this.filterShownData.forEach((element: any) => {
     if (element.key == columnName) {
       filteredData = element.value;
     }
   })
    }
    

    const columnValues = filteredData.map((item: any) => {
      if (item[columnName] instanceof Date) {
        // Format the date
        const date = new Date(item[columnName]);
        const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy');
        // Check if formattedDate is not null before using it
        if (formattedDate) {
          return this.commonHelperService.ValidateFilterData(formattedDate);
        }
      }
      return this.commonHelperService.ValidateFilterData(item[columnName]);
    });
    return [...new Set(columnValues as string[])];
  }


  selectedFilters: any = {};

  tempFilters: any = {};

  filterOptions: { [key: string]: string[] } = {};

  applyFilter(event: any, option: any, column: string) {
    // Convert option to a string
    this.isAllFiltersNotRemoved=false;
    let columnWiseFilterOptions:any={};
    let filteredValueAlreadyExists=false;
    const optionStr = option==null||option==undefined||option=="null"||option=="NULL"?"": String(option);
    if(this.firstAppliedFilterColumn.length==0){
      this.firstAppliedFilterColumn.push(column);
      columnWiseFilterOptions = {
        key: column,
        value: this.dataSource.filteredData
      }
      this.filterShownData.push(columnWiseFilterOptions);
    }
      else{
        this.firstAppliedFilterColumn.forEach((element:any) => {
          if(element==column){
            filteredValueAlreadyExists=true;
          }
        });
        if(!filteredValueAlreadyExists){this.firstAppliedFilterColumn.push(column);
          columnWiseFilterOptions={ 
            key:column,
            value:this.dataSource.filteredData
           }
              this.filterShownData.push(columnWiseFilterOptions);
        }
      }
    // If the checkbox is checked, add the option to the temporary filters
    if (event.checked) {
      this.commonHelperService.LoadFilterAppliedFields(event?.source?.id,column);
      this.isAllFiltersNotRemoved=true;
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.loadFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      if (this.tempFilters[column]) {
        this.tempFilters[column].push(optionStr ? optionStr.toLowerCase() : '');
      } else {
        this.tempFilters[column] = [optionStr ? optionStr.toLowerCase() : ''];
      }
    }
    } else {
      this.commonHelperService.RemoveFilterAppliedFields(event?.source?.id,column);
      // If the checkbox is not checked, remove the option from the temporary filters
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.removeFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      const index = this.tempFilters[column].indexOf(optionStr ? optionStr.toLowerCase() : '');
      if (index >= 0) {
        this.tempFilters[column].splice(index, 1);
      }
    }
     this.ValidateIsFilterExists();
    }
    if(this.isAllFiltersNotRemoved){this.applyFilters();}else{this.clearFilter("");}
    
  }
ValidateIsFilterExists(){
  this.displayedColumns.forEach((column:any)=>{
    if(this.tempFilters[column]?.length>0){this.isAllFiltersNotRemoved=true;}
    if(this.tempFilters[column]?.length==0){delete this.tempFilters[column];}
  })
}

  customFilterBck(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return Object.keys(searchTerms).every(name => {
        if (searchTerms[name].length === 0) {
          return true;  // If no filters are set on this column, then return true
        }
        let dataStr = '';
        if (data[name]) {
          // Convert data[name] to a string
          dataStr = String(data[name]).trim().toLowerCase();
        }
        return searchTerms[name].includes(dataStr);
      });
    };
    return filterFunction;
  }
  customFilter(): (data: any, filter: string) => boolean {
    return (data: any, filter: string): boolean => {
      const { searchText, filters } = JSON.parse(filter);
      const globalMatch = !searchText || Object.values(data).some(value =>
        String(value).toLowerCase().includes(searchText)
      );
 
      const columnMatch = Object.keys(filters).every(column => {
        return filters[column].includes(String(data[column]).toLowerCase());
      });
 
      return globalMatch && columnMatch;
    };
  }
  applyFilters() {
    this.ValidateIsFilterExists();
    // Copy tempFilters to selectedFilters
    this.selectedFilters = JSON.parse(JSON.stringify(this.tempFilters));

 // Trigger the filter
// this.dataSource.filter = JSON.stringify(this.selectedFilters);
if(!this.isAllFiltersNotRemoved){
 this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: {} });
}else{
 this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: this.selectedFilters });
}
 if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}

    // Update the filter options for all columns
    this.displayedColumns.forEach(column => {
      const uniqueValues = this.getUniqueColumnValues(column);
      // Update the filter options for the column
      this.updateFilterOptions(column, uniqueValues);
    });

  }

  updateFilterOptions(column: string, uniqueValues: string[]) {
    this.filterOptions[column] = uniqueValues;
  }


  clearFilter(column: string) {
    this.isAllFiltersNotRemoved=false;
    // Remove the filters for the column
    delete this.selectedFilters[column];
    delete this.tempFilters[column];
    this.ValidateIsFilterExists();
    // Reset the checkboxes
    this.clearCheckboxes(column);
    // this.checkboxes.forEach((checkbox:any) => {
    //   this.commonHelperService.filterAppliedFields.forEach((element:any) => {
    //     if(element.value==column&&checkbox.id==element.key||column==""){
    //       checkbox.checked = false;
    //     }
    //   });
    // });

    // Reset the filter predicate to the original one
   // this.dataSource.filterPredicate = this.customFilter();
    if(this.isAllFiltersNotRemoved||this.searchText!=""){
      this.applyFilters();
    }
    else{
      this.firstAppliedFilterColumn=[];
      this.filterShownData=[];
    // Trigger the filter
    this.dataSource.filter = "";

    // Fetch the data without any filters
    this.getMasterData(this.currentPageIndex + 1, this.paginator.pageSize, "ID", false);
    }

  }
  clearCheckboxes(column:any) {
    this.checkboxes.forEach((checkbox: MatCheckbox) => {
      this.commonHelperService.filterAppliedFields.forEach((element:any) => {
        if(element.value==column&&checkbox.id==element.key||column==""){
          checkbox.checked = false;
        }
      });
    });
  }
  applyGlobalFilter(filterValue: string) {
    // Show the spinner
    this.spinnerService.showSpinner();
    // delay to ensure spinner is visible
    setTimeout(() => {
    this.searchText = filterValue.trim().toLowerCase();
      this.applyFilters();
      // Hide the spinner after data is loaded
      this.spinnerService.hideSpinner();
    }, 500); //delay
  }
 
  resetFilters() {
    this.firstAppliedFilterColumn=[];
    this.filterShownData=[];
    this.searchText = '';
    this.filters = {};
    this.selectedFilters = {};
    this.tempFilters = {};
    this.applyFilters();
    this.clearCheckboxes("");
  }
  applyGlobalFilterBck(filterValue: any) {
    // Reset the filter predicate to the default
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      // Transform the data into a lowercase string of key value pairs.
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        // Check if data[key] is not null before concatenating
        return currentTerm + (data[key] ? data[key] : '') + '◬';
      }, '').toLowerCase();

      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();

      return dataStr.indexOf(transformedFilter) != -1;
    };

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetFiltersBck() {
    this.firstAppliedFilterColumn=[];
 this.filterShownData=[];
    // Clear the filter value
    this.dataSource.filter = '';
    this.searchText = '';

    // Clear the selected and temporary filters
    this.selectedFilters = {};
    this.tempFilters = {};

    // Reset the checkboxes
    this.checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });

    // Reset the filter predicate to the original one
    this.dataSource.filterPredicate = this.customFilter();

    this.sort.active = '';
    this.sort.direction = '';

    // Fetch the data without any filters
    this.getMasterData(this.currentPageIndex + 1, this.paginator.pageSize, "ID", false);
  }

}
