import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { UserService } from './auth.details';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService,
    private userService: UserService, private http: HttpClient) { }

  async canActivate(): Promise<boolean> {
    const account = this.authService.app.getActiveAccount();

    if (!account) {
      await this.authService.login();
      return false;
    }

    const details = await this.authService.getUserDetails();
    //const details_api = await this.authService.getUserDetails_api();


    this.userService.userName = details.userDetails.displayName;
    this.userService.userEmail = details.userDetails.mail;

    if (details.userPhoto) {
      this.userService.userPhoto = await this.createImageFromBlob(details.userPhoto);
    } else {
      this.userService.userPhoto = '../../assets/img/default.jpg';
    }

    this.userService.isAdmin = await this.isAdmin(this.userService.userEmail);

    return true;
  }
  
  async isAdmin(userEmail: string): Promise<boolean> {
    try {
      const options = {        
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("idToken")
        })
      };
      const result = await this.http.get<boolean>(environment.baseUrl + `/Admin/IsAdmin?userEmail=${userEmail}`,options).toPromise();
      return result !== undefined ? result : false;
    } catch (error) {
      console.error("Error checking admin status:", error);
      return false;
    }
  }

  createImageFromBlob(image: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      if (image) {
        reader.readAsDataURL(image);
      } else {
        reject("No image provided");
      }
    });
  }
}


