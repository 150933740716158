import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Injectable, OnChanges, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, tap } from 'rxjs';
import { MasterFormComponent } from '../master-form/master-form.component';
import { masterDashboard } from '../master-dashboard/_interface/master-dashboard.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { searchFormRequest } from './_interface/search-form.model';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { SpinnerServiceService } from '../services/spinner-service.service';
import { DatePipe } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
//import { Router, NavigationStart, Route } from '@angular/router';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { CommonHelperService } from '../services/common-helper.service';
import { UserService } from '../auth.details';
import { WindowRefService } from '../services/window.service';

interface ColumnDef<T> {
  columnDef: string;
  header: string;
  cell: (element: T) => string;
}
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrl: './search-form.component.css'
})
export class SearchFormComponent implements AfterViewInit, OnInit {
  columns!: ColumnDef<any>[];
  isAllFiltersNotRemoved:boolean=false;
  firstAppliedFilterColumn:any=[];
  isLoading = false;
  showDashboard = false;
  filterShownData:any=[];
  isGridDataAvailable:boolean=true;
  isInvalidDate:any=false;
  isEditMode!: boolean;
  searchFormRequest: searchFormRequest = {
    serviceOrganization: '',
    application: '',
    serviceAuditor: '',
    periodStartDate: null,
    periodEndDate: null
  };
  private pageSubscription?: Subscription;
  public dataSource = new MatTableDataSource<masterDashboard>();
  public displayedColumns = ['ServiceOrganization', 'Application', 'ServiceAuditor', 'ReportPeriodStartDate',
    'ReportPeriodEndDate', 'LocationsCovered', 'TypeOfOpinion', 'SARReportType', 'SARAuthoritativeGuidance', 'IDNumber'
  ];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;
  constructor(private dialog: MatDialog, private http: HttpClient, private appInsightsService: AppInsightsService,
    private changeDetector: ChangeDetectorRef, private spinnerService: SpinnerServiceService, private datePipe: DatePipe, 
    private router: Router,private commonHelperService:CommonHelperService,private userService: UserService,private windowService: WindowRefService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.spinnerService.showSpinner();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.spinnerService.hideSpinner();
      }
    });
  }

  ngOnInit() {
    if (this.windowService.nativeWindow._satellite) {
      //alert("got the satellite obj");           


      let analyticsDataLayer =
      {
        'pageInfo':
        {
          'pageName': "Search Form", // Page Name
          'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
          'applicationName': "SAR" // Name of application
        }
      }
     
      this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
      this.windowService.nativeWindow._satellite.track("pageView");
     
    }
  

  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.customFilter();
  }

  loadPage() {
    this.searchMasterList(this.searchFormRequest, this.paginator.pageIndex + 1, this.paginator.pageSize);
  }

  getfiltercolour(column: string) {
    const hasIdKey = Object.prototype.hasOwnProperty.call(this.tempFilters, column);
    return hasIdKey;
  }

  public searchMasterList = (searchParameters: searchFormRequest, pageNumber: number, pageSize: number) => {
    this.isLoading = true;
    this.spinnerService.showSpinner(); //show spinner
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    const options = {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    };

    this.http.post<{ Data: masterDashboard[], Total: number }>(environment.baseUrl + '/MasterList/SearchMasterList', searchParameters, options).subscribe({
      next: response => {
        if(response.Data.length==0){this.isGridDataAvailable=false;this.showDashboard=false;}else{this.isGridDataAvailable=true;this.showDashboard=true;}
        this.dataSource.data = response.Data;
        let defaultData={
          key:"",
          value:response.Data
        }
        this.filterShownData.push(defaultData);
        this.isLoading = false;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.paginator.pageIndex = pageNumber - 1;
          this.paginator.pageSize = pageSize;
          this.paginator.length = response.Total;
        }, 0);
        this.spinnerService.hideSpinner(); // Hide spinner after data is loaded
      },
      error: error => {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while fetching data!', error);
        this.spinnerService.hideSpinner(); //Hide spinner in case of error
      }
    });
  }


  onClearClick(form: NgForm) {
    this.showDashboard=false;
    this.isInvalidDate=false;
    this.isGridDataAvailable=true;
    form.resetForm();
  }
ValidateSearchRequest(){
  if(this.searchFormRequest!=null){
    this.searchFormRequest.serviceOrganization=this.searchFormRequest.serviceOrganization==null?'':this.searchFormRequest.serviceOrganization;
    this.searchFormRequest.application=this.searchFormRequest.application==null?'':this.searchFormRequest.application;
    this.searchFormRequest.serviceAuditor=this.searchFormRequest.serviceAuditor==null?'':this.searchFormRequest.serviceAuditor;
  }
}
ValidateDates(startDate:any,endDate:any):boolean{
  let isInValid=false;
  if(startDate==null&&endDate==null){
    isInValid= false;
  }
  else{
    if(startDate?.validationMessage=="Please enter a valid value. The field is incomplete or has an invalid date."||
      endDate?.validationMessage=="Please enter a valid value. The field is incomplete or has an invalid date."){
        isInValid= true;
    }
  }
  return isInValid;

}
  onSubmit(form: NgForm,startDate:any,endDate:any) {
    this.isGridDataAvailable=true; this.showDashboard=false;
    this.isInvalidDate=this.ValidateDates(startDate,endDate);
    if (form.valid&&!this.isInvalidDate) {
      this.ValidateSearchRequest();//Added this to pass valid paramsto the API
      this.searchMasterList(this.searchFormRequest, 1, 50);
      //this.showDashboard = true;

      // Run change detection to update the view
      this.changeDetector.detectChanges();

      // Assign the sort and paginator to the data source
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      // Unsubscribe from the previous subscription
      if (this.pageSubscription) {
        this.pageSubscription.unsubscribe();
      }

      // Create a new subscription
      this.pageSubscription = this.paginator.page
        .pipe(
          tap(() => this.loadPage())
        )
        .subscribe();
    }
  }
  //VIEW ITEM
  viewItem(element: any) {
    this.isEditMode = false;
    const dialogRef = this.dialog.open(MasterFormComponent, {
      data: {
        ...element,
        isEditMode: this.isEditMode
      }, panelClass: 'custom-dialog', height: '510px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => { });
  }
  //FILTER
  getUniqueColumnValues(columnName: string): string[] {
    // Get the filtered data
    let filteredData:any;
    filteredData=this.dataSource.filteredData;
 if(this.firstAppliedFilterColumn.length>0){
   this.filterShownData.forEach((element: any) => {
     if (element.key == columnName) {
       filteredData = element.value;
     }
   })
   }

    const columnValues = filteredData.map((item: any) => {
      if (item[columnName] instanceof Date) {
        // Format the date
        const date = new Date(item[columnName]);
        const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy');
        // Check if formattedDate is not null before using it
        if (formattedDate) {
          return this.commonHelperService.ValidateFilterData(formattedDate);
        }
      }
      return this.commonHelperService.ValidateFilterData(item[columnName]);
    });
    return [...new Set(columnValues as string[])];
  }


  selectedFilters: any = {};

  tempFilters: any = {};

  filterOptions: { [key: string]: string[] } = {};
  ValidateIsFilterExists(){
    this.displayedColumns.forEach((column:any)=>{
      if(this.tempFilters[column]?.length>0){this.isAllFiltersNotRemoved=true;}
      if(this.tempFilters[column]?.length==0){delete this.tempFilters[column];}
    })
  }
  applyFilter(event: any, option: any, column: string) {
    // Convert option to a string
    this.isAllFiltersNotRemoved=false;
    let columnWiseFilterOptions:any={};
    let filteredValueAlreadyExists=false;
    const optionStr =option==null||option==undefined||option=="null"||option=="NULL"?"": String(option);
    if(this.firstAppliedFilterColumn.length==0){
      this.firstAppliedFilterColumn.push(column);
      columnWiseFilterOptions = {
        key: column,
        value: this.dataSource.filteredData
      }
      this.filterShownData.push(columnWiseFilterOptions);
    }
      else{
        this.firstAppliedFilterColumn.forEach((element:any) => {
          if(element==column){
            filteredValueAlreadyExists=true;
          }
        });
        if(!filteredValueAlreadyExists){this.firstAppliedFilterColumn.push(column);
          columnWiseFilterOptions={ 
            key:column,
            value:this.dataSource.filteredData
           }
              this.filterShownData.push(columnWiseFilterOptions);
        }
      }
    // If the checkbox is checked, add the option to the temporary filters
    if (event.checked) {
      this.commonHelperService.LoadFilterAppliedFields(event?.source?.id,column);
      this.isAllFiltersNotRemoved=true;
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.loadFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      if (this.tempFilters[column]) {
        this.tempFilters[column].push(optionStr ? optionStr.toLowerCase() : '');
      } else {
        this.tempFilters[column] = [optionStr ? optionStr.toLowerCase() : ''];
      }
    }
    } else {
      this.commonHelperService.RemoveFilterAppliedFields(event?.source?.id,column);
      // If the checkbox is not checked, remove the option from the temporary filters
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.removeFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      const index = this.tempFilters[column].indexOf(optionStr ? optionStr.toLowerCase() : '');
      if (index >= 0) {
        this.tempFilters[column].splice(index, 1);
      }
    }
      this.ValidateIsFilterExists();
    }
    if(this.isAllFiltersNotRemoved){this.applyFilters();}else{this.clearFilter("");}
  }


  customFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return Object.keys(searchTerms).every(name => {
        if (searchTerms[name].length === 0) {
          return true;  // If no filters are set on this column, then return true
        }
        let dataStr = '';
        if (data[name]) {
          // Convert data[name] to a string
          dataStr = String(data[name]).trim().toLowerCase();
        }
        return searchTerms[name].includes(dataStr);
      });
    };
    return filterFunction;
  }

  applyFilters() {
    // Copy tempFilters to selectedFilters
    this.selectedFilters = JSON.parse(JSON.stringify(this.tempFilters));

    // Trigger the filter
    this.dataSource.filter = JSON.stringify(this.selectedFilters);

    // Update the filter options for all columns
    this.columns.forEach(column => {
      const uniqueValues = this.getUniqueColumnValues(column.columnDef);
      // Update the filter options for the column
      this.updateFilterOptions(column.columnDef, uniqueValues);
    });
  }

  updateFilterOptions(column: string, uniqueValues: string[]) {
    this.filterOptions[column] = uniqueValues;
  }


  clearFilter(column: string) {
    this.isAllFiltersNotRemoved=false;
    // Remove the filters for the column
    delete this.selectedFilters[column];
    delete this.tempFilters[column];

    this.ValidateIsFilterExists();
 // Reset the checkboxes
 this.checkboxes.forEach((checkbox:any) => {
   this.commonHelperService.filterAppliedFields.forEach((element:any) => {
     if(element.value==column&&checkbox.id==element.key||column==""){
       checkbox.checked = false;
     }
   });
 });

    // Reset the filter predicate to the original one
    this.dataSource.filterPredicate = this.customFilter();
    if(this.isAllFiltersNotRemoved){
      this.applyFilters();
    }
    else{
      this.firstAppliedFilterColumn=[];
      this.filterShownData=[];
    // Trigger the filter
    this.dataSource.filter = "";

    // Fetch the data without any filters
    let parameters:any={valid:true};
    this.onSubmit(parameters,null,null);
    }
  }
}
