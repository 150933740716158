import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminviewServiceService {

  constructor(private http: HttpClient) { }
  updateMultiExistingRequest(data:any):Observable<any>{        
    const url = environment.baseUrl +'/Admin/UpdateMultiAdminViewExistingRequestReviewList';
    return this.http.post(url,data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("idToken")})
      });
  }
}
